import { Remirror, useRemirror } from '@remirror/react';
import _ from 'lodash';
import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { InvalidContentHandler } from 'remirror';
import {
  BlockquoteExtension,
  BoldExtension,
  BulletListExtension,
  DropCursorExtension,
  FontSizeExtension,
  HardBreakExtension,
  HeadingExtension,
  HorizontalRuleExtension,
  IframeExtension,
  ImageExtension,
  ItalicExtension,
  LinkExtension,
  MarkdownExtension,
  MentionAtomExtension,
  NodeFormattingExtension,
  OrderedListExtension,
  ShortcutsExtension,
  StrikeExtension,
  TextColorExtension,
  TextHighlightExtension,
  UnderlineExtension,
} from 'remirror/extensions';

import { TextEditorFileUploadExtension } from './TextEditorFileUpload';
import { TextEditorProps } from './TextEditorTypes';

type TextEditorContextType = Pick<
  TextEditorProps,
  'onUploadFile' | 'mentionOptions'
>;
export const TextEditorContext = createContext<TextEditorContextType>(
  {} as TextEditorContextType,
);

export const TextEditorProvider = ({
  children,
  ...rest
}: { children: React.ReactNode } & TextEditorProps) => {
  const {
    initialValue = '',
    value = '',
    placeholder = 'Enter your text',
    onChange: _onChange,
    onUploadFile = undefined,
    controlKey = null,
    mentionOptions = [],
  } = rest;
  // @ts-ignore
  const extensions = useTextEditorExtensions({ onUploadFile, mentionOptions });
  const onError: InvalidContentHandler = useCallback(
    ({ json, invalidContent, transformers }) => {
      // Automatically remove all invalid nodes and marks.
      return transformers.remove(json, invalidContent);
    },
    [],
  );
  const controlKeyRef = useRef<string | null>(null);
  const { manager, state, setState } = useRemirror({
    extensions,
    content: value || initialValue,
    selection: 'start',
    stringHandler: 'html',
    onError,
  });

  useEffect(() => {
    if (controlKeyRef.current) {
      setState(
        manager.createState({
          content: value || '',
          stringHandler: 'html',
        }),
      );
    }
    controlKeyRef.current = controlKey;
  }, [controlKey]);

  const onChange = useCallback(
    (param) => {
      setState(param.state);
      const htmlText = param.helpers.getHTML(param.state);
      const markdown = param.helpers.getMarkdown(param.state);
      if (_.isEmpty(markdown)) {
        _onChange('', '');
      } else {
        _onChange(htmlText, markdown);
      }
    },
    [_onChange],
  );

  return (
    <TextEditorContext.Provider value={{ onUploadFile, mentionOptions }}>
      <Remirror
        manager={manager}
        state={state}
        placeholder={placeholder}
        onChange={onChange}
        classNames={['texteditor__component']}
      >
        {children}
      </Remirror>
    </TextEditorContext.Provider>
  );
};

const useTextEditorExtensions = ({
  onUploadFile: _onUploadFile = undefined,
  mentionOptions = [],
}: {
  mentionOptions: unknown[];
  onUploadFile: TextEditorProps['onUploadFile'];
}) => {
  const onUploadImage = useCallback(
    (filesWithProgress) => {
      if (!_onUploadFile) return;
      return filesWithProgress.map(({ file }) => {
        return async () => {
          const src = await _onUploadFile(file);
          return {
            src: src,
            name: file.name,
            title: file.name,
            width: '90%',
            minWidth: '50px',
            objectFit: 'contain',
          };
        };
      });
    },
    [_onUploadFile],
  );

  return useMemo(() => {
    const extensions = [
      new HorizontalRuleExtension({}),
      new UnderlineExtension(),
      new BlockquoteExtension(),
      new StrikeExtension(),
      new FontSizeExtension({ defaultSize: '15px', unit: 'px' }),
      new ItalicExtension(),
      new NodeFormattingExtension({}),
      new TextColorExtension({ defaultColor: '#333333' }),
      new TextHighlightExtension({}),
      new BoldExtension({}),
      new HeadingExtension({}),
      new HardBreakExtension(),
      new MarkdownExtension({}),
      new IframeExtension({ enableResizing: true }),
      new BulletListExtension({}),
      new OrderedListExtension(),
      new DropCursorExtension({}),
      new ShortcutsExtension(),
      new MentionAtomExtension({
        matchers: [{ name: 'variable', char: '{{', matchOffset: 0 }],
      }),
    ];
    if (_onUploadFile) {
      extensions.push(
        // @ts-ignore
        new ImageExtension({
          uploadHandler: onUploadImage,
          enableResizing: true,
        }),
      );
      //
      extensions.push(
        // @ts-ignore
        new TextEditorFileUploadExtension(),
      );
    }
    extensions.push(
      // @ts-ignore
      new LinkExtension({
        autoLink: true,
        defaultTarget: '_blank',
        selectTextOnClick: true,
        // allow custom styles on links
        markOverride: { excludes: undefined },
      }),
    );
    return () => extensions;
  }, [onUploadImage, mentionOptions]);
};
