import { createBrowserHistory } from 'history';
import React, { Suspense, lazy, useCallback, useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import {
  ConfirmationModalProvider,
  ModalManagerProvider,
  Tooltip,
} from '@nl-lms/ui/components';
import { C } from '@nl-lms/ui/constants';
import { useTracker } from '@nl-lms/ui/modules';
import { NotificationsProvider } from '@nl-lms/ui/modules';

import { AbilityContext } from './Can';
import {
  authStore,
  handleTokenRefreshing,
  setSettings,
  startSessionTrace,
} from './_common/modules/Auth/auth';
import { ErrorBoundaryContainer } from './_common/modules/ErrorBoundaryContainer/ErrorBoundaryContainer';
import GlobalListeners from './_common/modules/GlobalListeners';
import ServiceDownPage from './_common/modules/ServiceDownPage/ServiceDownPage';
import { api } from './_common/services/api';
import ability from './ability';
import { NotificationTemplateProvider } from './adminApp/modules/notifications/NotificationTemplateProvider';
import './index.scss';
import './lib/i18n/i18n';
import { updateLanguage } from './lib/i18n/updateLanguage';
import { overrideConsole } from './lib/logger';
import { overrideAll } from './lib/overrides';
import * as sentry from './lib/sentry';
import { store } from './store';

const Routes = lazy(() => import('./Routes'));

const history = createBrowserHistory();
if (C.SENTRY_DSN) {
  sentry.init(C.SENTRY_DSN, {
    history,
    user: authStore.user,
    customerId: C.CUSTOMER_ID,
    version: C.VERSION,
  });
}

export const App = () => {
  const tracker = useTracker();

  const [initStatus, setInitStatus] = useState<
    'loading' | 'complete' | 'error'
  >('loading');

  const onInit = useCallback(async () => {
    try {
      const settings = await api.common.getSettings();

      //@ts-ignore
      handleTokenRefreshing();
      setSettings(settings);
      overrideConsole();
      overrideAll();
      updateLanguage(authStore.activeApp, authStore.user);

      tracker.load();

      authStore.onChange('activeApp', (activeApp) => {
        updateLanguage(activeApp, authStore.user);
      });

      authStore.onChange('user', (user, oldUser) => {
        if (oldUser && !user) {
          tracker.unload();
        } else if (!oldUser && user) {
          tracker.load();
        } else if (!oldUser && !user) {
          tracker.unload();
        } else if (oldUser && user) {
          tracker.load();
        }

        updateLanguage(authStore.activeApp, user);
      });

      setInitStatus('complete');
    } catch (e) {
      console.error("App can't start.", e);
      setInitStatus('error');
    }
  }, [tracker]);

  useEffect(() => {
    startSessionTrace();
    onInit();
  }, []);

  if (initStatus === 'loading') {
    return null;
  }

  if (initStatus === 'error') {
    return <ServiceDownPage />;
  }

  return (
    <AbilityContext.Provider value={ability}>
      <Provider store={store}>
        <Tooltip.provider>
          <DndProvider backend={HTML5Backend}>
            <NotificationsProvider>
              <NotificationTemplateProvider>
                <BrowserRouter>
                  <ConfirmationModalProvider>
                    <ModalManagerProvider>
                      <ErrorBoundaryContainer>
                        <Suspense
                          fallback={
                            <div id="main-loader">
                              <div id="main-loader-components">
                                <div id="main-loader-logo">
                                  <img
                                    className="main-layout__logo-image"
                                    src={C.LOGO_DETAILS.variation.small}
                                  />
                                </div>
                              </div>
                            </div>
                          }
                        >
                          <GlobalListeners>
                            <Routes />
                          </GlobalListeners>
                        </Suspense>
                      </ErrorBoundaryContainer>
                    </ModalManagerProvider>
                  </ConfirmationModalProvider>
                </BrowserRouter>
              </NotificationTemplateProvider>
            </NotificationsProvider>
          </DndProvider>
        </Tooltip.provider>
      </Provider>
    </AbilityContext.Provider>
  );
};
