import enUS from 'date-fns/locale/en-GB';

export {
  format,
  add,
  intervalToDuration,
  sub,
  formatDuration,
  getDay,
  parse,
  formatDistance,
  compareAsc,
  toDate,
  isEqual,
  getDate,
  getHours,
  differenceInMinutes,
  differenceInHours,
  startOfDay,
  endOfDay,
  areIntervalsOverlapping,
  startOfWeek,
  endOfWeek,
  startOfYear,
  endOfYear,
  startOfMonth,
  endOfMonth,
  startOfQuarter,
  endOfQuarter,
  setQuarter,
  set,
} from 'date-fns';

export const locales = {
  enUS,
};
