import { yupResolver } from '@hookform/resolvers/yup';
import React, { Component, useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useErrorBoundary } from 'use-error-boundary';
import * as yup from 'yup';

import {
  Button,
  Card,
  FloatingMenu,
  FormField,
  Input,
  Separator,
  Typography,
  useShowModal,
} from '@nl-lms/ui/components';
import { C } from '@nl-lms/ui/constants';

import { routes } from '../../../lib/routes';
import { useAction } from '../../hooks/useAction';
import { adminApi } from '../../services/api';
import { authStore } from '../Auth/auth';
import { TextEditorWithFileUpload } from '../TextEditorWithFileUpload';
import './ErrorBoundaryContainer.scss';

type ErrorBoundaryContainerProps = {
  children: React.ReactNode;
};

const { useSendSupportEmailMutation } = adminApi;

const Schema = yup.object().shape({
  title: yup.string().required(),
  message: yup.string().required(),
});

const ErrorPage = ({
  onNavigate,
  error,
}: {
  onNavigate: () => void;
  error: any | null;
}) => {
  const [wasErrorReported, setWasErrorReported] = useState(false);

  const navigate = useNavigate();

  const onClickGoToPrevPage = useCallback(() => {
    navigate(-1);
    onNavigate();
  }, [history, onNavigate]);

  const onClickGoToPage = useCallback(
    (path) => {
      navigate(path);
      onNavigate();
    },
    [history, onNavigate],
  );

  const onClickReload = useCallback(() => {
    location.reload();
    onNavigate();
  }, [onNavigate]);

  const navigationItems = [
    { name: 'Previous Page', handler: onClickGoToPrevPage },
    {
      name: 'Learning Activity',
      handler: () => onClickGoToPage(routes.admin.activity.path.full()),
    },
    {
      name: 'Analytics',
      handler: () => onClickGoToPage(routes.admin.analytics.path.full()),
    },
    {
      name: 'Catalogue',
      handler: () => onClickGoToPage(routes.admin.catalogue.path.full()),
    },
    {
      name: 'Learners',
      handler: () => onClickGoToPage(routes.admin.learners.path.full()),
    },
  ];

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(Schema),
    mode: 'onSubmit',
    defaultValues: {
      title: '',
      message: '',
    },
  });

  const [sendSupportEmail, { isLoading }] = useSendSupportEmailMutation();

  const onSubmit = useCallback(
    async ({ title, message }) => {
      const errorString =
        error instanceof Error
          ? error.stack || error.toString()
          : typeof error === 'string'
            ? error
            : JSON.stringify(error);

      const result = await sendSupportEmail({
        title,
        message,
        metadata: {
          url: window.location.href,
          path: window.location.pathname,
          customerId: C.CUSTOMER_ID,
          error: errorString,
          learnerId: authStore?.learnerId,
          userId: authStore?.user?.id,
          roles: (authStore?.user?.roles || []).join(', '),
          activeApp: authStore?.activeApp,
        },
      });
      if (result.data) {
        setWasErrorReported(true);
      } else {
        throw new Error('Failed to send crash report');
      }
    },
    [error],
  );

  const onSubmitAction = useAction(handleSubmit(onSubmit), {
    successMessage: 'The issue has been reported',
    alertMessage: 'Failed to report the issue.',
    alertDetails: `Please try again or contact support at ${C.FEATURES.customerSupportNotificationEmail} if the issue persists.`,
  });

  return (
    <div className="error-boundary-container">
      <Card className="error-boundary-container__card" paddingType="medium">
        <Card.Body noPadding>
          <Typography.h1>Something happened</Typography.h1>
          <Separator margin={20} />

          {wasErrorReported ? (
            <>
              <Typography.p>
                Thank you for the additional information. We'll follow up on you
                once we complete our investigation. In the meantime you can
                navigate away from this crash page by using one of the buttons
                below.
              </Typography.p>
            </>
          ) : (
            <>
              <Typography.p>
                We've been notified and are looking into it. If you'd like to
                help us fix this faster, please describe what happened below.
              </Typography.p>

              <br />

              <FormField
                label="Title"
                required
                errorMessage={errors.title?.message}
              >
                <Input
                  {...register('title')}
                  placeholder="Short description of the issue"
                />
              </FormField>

              <FormField
                label="What happened?"
                required
                errorMessage={errors.message?.message}
              >
                <Controller
                  name="message"
                  control={control}
                  render={({ field }) => (
                    <TextEditorWithFileUpload
                      onChange={(html, markdown) =>
                        field.onChange({ target: { value: markdown } })
                      }
                      value={field.value}
                      initialValue={field.value}
                      placeholder={`Please provide:\n1. A brief summary of what happened\n2. Steps to reproduce the issue (if applicable)\n3. Screenshots - you can paste or drop images here\nExtra details about the problem will be automatically added (URL, user, etc.) Note: Please ensure no sensitive data is included in screenshots.`}
                    />
                  )}
                />
              </FormField>
            </>
          )}
        </Card.Body>

        <Card.Actions>
          <Button regular label="Reload Page" onClick={onClickReload} />

          <FloatingMenu
            items={navigationItems}
            className="error-boundary__action"
          >
            <Button regular={!wasErrorReported} label="Go To" />
          </FloatingMenu>

          {!wasErrorReported ? (
            <Button
              isLoading={isLoading}
              disabled={wasErrorReported}
              onClick={onSubmitAction}
              label="Send Crash Report"
            />
          ) : null}
        </Card.Actions>
      </Card>
    </div>
  );
};

export const ErrorBoundaryContainer = ({
  children,
}: ErrorBoundaryContainerProps) => {
  const { error, reset, ErrorBoundary, didCatch } = useErrorBoundary();

  const showErrorModal = useShowModal(ErrorPage);

  if (didCatch) return <ErrorPage error={error} onNavigate={reset} />;

  return <ErrorBoundary>{children}</ErrorBoundary>;
};
