import React, { useMemo } from 'react';

import { AppQuery } from '@nl-lms/common/shared';
import { LearnerGroup } from '@nl-lms/feature/organization/sdk';
import { transformTsRestQuery } from '@nl-lms/sdk/backend';
import { MultiSelect, SingleSelect } from '@nl-lms/ui/components';

import { organizationApi } from '../../../_common/services/api';

const { useListLearnerGroupsQuery } = organizationApi;

export const AdminLearnerGroupSingleSelect = (props) => {
  const {
    onChange,
    name,
    initialSelectedItem = null,
    selectedItem = null,
    returnEntireItemOnChange = false,
    placeholder = 'Search by group name',
  } = props;

  const query = useMemo<AppQuery>(
    () => ({
      pagination: { offset: 0, limit: 1000 },
      sorting: [['title', 'asc']],
    }),
    [],
  );
  const { data, isLoading } = useListLearnerGroupsQuery({
    query: { query: transformTsRestQuery(query) },
  });
  const learnerGroups = data ? data.rows : [];
  const options = useMemo(() => {
    return learnerGroups.map((group) => ({
      value: group.id,
      label: `${group.title} (${group.learnersCount})`,
      entity: group,
    }));
  }, [learnerGroups]);
  return (
    <SingleSelect
      name={name}
      placeholder={placeholder}
      onChange={onChange}
      isLoading={isLoading}
      returnEntireItemOnChange={returnEntireItemOnChange}
      initialSelectedItem={initialSelectedItem}
      selectedItem={selectedItem}
      options={options}
    />
  );
};

const parseOptions = (res: LearnerGroup[]) =>
  res.map((group) => ({
    value: group.id,
    label: `${group.title} (${group.learnersCount})`,
    entity: group,
  }));

const parseSelectedItems = (selectedItemsIds: string[], options) => {
  if (!selectedItemsIds) return null;
  return options.filter((o) => selectedItemsIds.includes(o.value));
};

export const AdminLearnerGroupMultiSelect = (props) => {
  const {
    onChange,
    name,
    placeholder = 'Search by group name',
    initialSelectedItems = [],
    selectedItems = undefined,
    returnEntireItemOnChange = false,
    disabled = false,
  } = props;

  const query = useMemo<AppQuery>(
    () => ({
      pagination: { offset: 0, limit: 9999 },
      sorting: [['title', 'asc']],
    }),
    [],
  );
  const { data, isLoading: isFetchLoading } = useListLearnerGroupsQuery({
    query: { query: transformTsRestQuery(query) },
  });

  const options = useMemo(() => {
    if (!data) return [];
    return parseOptions(data.rows);
  }, [data]);

  return (
    <MultiSelect
      name={name}
      placeholder={placeholder}
      isLoading={isFetchLoading}
      onChange={onChange}
      selectedItems={parseSelectedItems(selectedItems, options)}
      initialSelectedItems={initialSelectedItems}
      returnEntireItemOnChange={returnEntireItemOnChange}
      options={options}
      disabled={disabled}
    />
  );
};
